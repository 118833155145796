<template>
    <div class="page-grid">
        <div class = "alain-pro__page-grid">
        <a-spin :spinning="false">
            <div style = "width: 90%; margin: 5rem auto;">
                <a-row>
                    <a-col :span = "8" :offset="8">
                        <a-steps :current="0" labelPlacement="vertical">
                          <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                        </a-steps>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8" :offset="8">
                        <div style = "margin: 3rem auto 5rem;">
                            <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
                                <a-form-item label="手机号" required>
                                    <a-input size="large"></a-input>
                                </a-form-item>
                                <a-form-item label="验证码" required>
                                    <a-input placeholder="请输入验证码" size="large">
                                        <div slot="suffix">
                                            获取验证码
                                        </div>
                                    </a-input>
                                </a-form-item>
                                <a-form-item>
                                    <a-row>
                                        <a-col :span="16" offset="7">
                                            <a-button>下一步</a-button>
                                        </a-col>
                                    </a-row>
                                </a-form-item>
                            </a-form>
                        </div>
                    </a-col>
                </a-row>
            </div>
        </a-spin>
    </div>
    </div>
</template>

<script>
export default {
    name: "retrieve-password",
    data() {
        return {
            steps:[
                {title:'安全验证'},
                {title:'重设密码'},
                {title:'完成'},
            ]
        }
    },
    methods: {}
}
</script>

<style lang = "less" scoped>
.alain-pro__page-grid {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100%;
    transition: .3s;
}

.page-grid {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;

}
    .alain-pro__page-grid {
        background: #f2f3f5 url('../../assets/img/bg-password.png') no-repeat 50%/100%;
    }
</style>